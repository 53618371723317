import axios from 'axios'

const path = process.env.VUE_APP_BASE_API + 'biddingSystemPreRegistrations'

export default {
    create(payload) {
        return axios.post(`${path}`, payload)
    },

    userCreate(payload) {
        return axios.post(`${path}/users`, payload)
    },
}
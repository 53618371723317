const fields = {
    password: 'senha'
};

export default {
    email: () => 'Insira um endereço de e-mail válido.',
    required: () => 'Campo obrigatório.',
    maxLength: ({$params}) => `O campo deve ter no maxímo ${$params.maxLength.max} caracteres.`,
    minLength: ({$params}) => `O campo deve ter no mínimo ${$params.minLength.min} caracteres.`,
    minValue: ({$params}) => `O campo deve conter o valor mínimo ${$params.minValue.min}.`,
    maxValue: ({$params}) => `O campo deve conter o valor máximo ${$params.maxValue.max}.`,
    between: ({$params}) => `O campo deve está entre ${$params.between.min} e ${$params.between.max}.`,
    numeric: () => `O campo deve conter um valor numérico.`,
    requiredIf: () => `Campo obrigatório.`,
    check_terms: () => `Confirme os termos.`,
    sameAs: ({$params}) => `O campo deve ser igual ao campo ${fields[$params.sameAs.eq] ?? $params.sameAs.eq}`,
    hasNumber: () => `O campo deve conter pelo menos um número.`,
    hasLowercase: () => `O campo deve conter pelo menos uma letra maiúscula.`,
    hasUppercase: () => `O campo deve conter pelo menos uma letra minúscula.`,
    hasSpecial: () => `O campo deve conter pelo menos um caractere especial`,
}
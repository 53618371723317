import messageAlert from "./messageAlert";

function getErrors(responseErrors) {
    let errors = ''

    for (let err in responseErrors) {
        if (responseErrors.hasOwnProperty(err)) {
            errors += `${responseErrors[err][0]}<br />`
        }
    }

    return errors
}

export default (error) => {
    if (typeof error.response !== 'undefined' && error.response.status === 422) {

        let errors = ''

        if(error.response.data.hasOwnProperty('data')) {
            errors = getErrors(error.response.data.data)
        }

        if(error.response.data.hasOwnProperty('errors')) {
            errors = getErrors(error.response.data.errors)
        }

        messageAlert(errors, 'error')

    } else {
        let message = ''
        if (typeof error === "string") {
            message = error
        } else {
            message = typeof error.response.data.message !== 'undefined' ? error.response.data.message : 'Erro ao efetuar a ação!'
        }

        messageAlert(message, 'error')
    }
}